<template>
	<screen size="small" class="auth-view">
		<template slot="body">
			<div class="auth-header">
				<h3>Forgot Password</h3>
			</div>
			<div class="auth-content">
				<p>
					Enter your email below to have a link emailed to you with
					instructions on how to reset your password.
				</p>
				<form v-on:submit.prevent="onSubmit">
					<ui-label>
						<ui-input v-model="email" placeholder="Email" />
					</ui-label>
					<ui-label>
						<ui-button native-type="submit" :loading="isLoading"
							>Reset Password</ui-button
						>
					</ui-label>
					<div class="forgot-password">
						<router-link :to="{ name: 'login' }"
							>Back To Login</router-link
						>
					</div>
				</form>
			</div>
		</template>
	</screen>
</template>

<script>
import { mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'

export default {
	name: 'forgot-password',
	components: { Screen, UiButton, UiInput, UiLabel },
	data() {
		return {
			isLoading: false,
			email: ''
		}
	},
	methods: {
		...mapActions(['forgotPassword']),
		onSubmit() {
			this.isLoading = true
			this.forgotPassword(this.email)
				.then(response => {
					this.isLoading = false
					this.$swal('Success', response.message).then(() => {
						this.navigate({ name: 'welcome' }, 'down')
					})
				})
				.catch(({ message }) => {
					this.isLoading = false
					this.$swal('Error', message)
				})
		}
	}
}
</script>

<style lang="scss">
.screen.auth-view.forgot-password {
	font-size: 13px;
}
</style>
